import React from "react"

//export default () => <div>Just Testing!</div>

export default () => (
    <div style={{ color: `teal` }}>
    <h1>About Gatsby</h1>
    <p>Such wow. Very React.</p>    
  </div>
  )
